<template>
  <div class="page-wrapper">
    <MoleculePageHeader :title="$t('pages.projects.title')" />
    <div class="page-body">
      <div class="container-xl">
        <div class="row row-cards">
          <div class="col-lg-8">
            <div
              v-if="loading"
              class="row row-cards"
            >
              <div
                v-for="n in 4"
                :key="n"
                class="col-12"
              >
                <MoleculeCard body-class="p-4">
                  <div class="row align-items-center justify-content-between">
                    <div class="col">
                      <div class="placeholder col-5 mb-1"></div>
                      <div class="placeholder placeholder-xs col-9 mt-3"></div>
                    </div>
                    <div class="col-2">
                      <span
                        class="btn btn-primary disabled placeholder w-100"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </MoleculeCard>
              </div>
            </div>
            <div
              v-else-if="allProjects.length"
              class="row row-cards"
            >
              <div
                v-for="project in allProjects"
                :key="project.id"
                class="col-12"
              >
                <MoleculeCard body-class="p-4">
                  <div class="row row-cards align-items-center">
                    <div class="col-12 col-sm">
                      <h2 class="m-0">{{ project.name }}</h2>
                      <div
                        v-if="project.address"
                        class="mt-2"
                      >
                        <span class="text-muted">{{ $t('address.label') }}:</span> {{ project.address }}
                      </div>
                    </div>
                    <div class="col-12 col-sm-auto">
                      <div class="btn-list">
                        <AtomButton
                          v-if="project.houseId"
                          :to="'board/' + project.alias"
                        >
                          <layout-grid-icon /> {{ $t('pages.board.title') }}
                        </AtomButton>
                        <AtomButton
                          v-if="project.website"
                          :href="project.link"
                          target="_blank"
                        >
                          <external-link-icon />{{ project.website }}
                        </AtomButton>
                        <AtomButton
                          v-if="project.path"
                          :to="{ name: 'File Manager', params: { path: project.path } }"
                          color="primary"
                        >
                          <files-icon />{{ $t('pages.filemanager.title') }}
                        </AtomButton>
                      </div>
                    </div>
                  </div>
                </MoleculeCard>
              </div>
            </div>
            <MoleculeEmpty
              v-else
              :message="$t('project.many.error.empty')"
            >
              <AtomButton
                to="/"
                color="primary"
              >
                <chevron-left-icon />{{ $t('action.back_home') }}
              </AtomButton>
            </MoleculeEmpty>
          </div>
          <div class="col-lg-4">
            <div class="row row-cards">
              <div class="col-12">
                <MoleculeCard>
                  <h3 class="card-title">{{ $t('manager.many.label') }}:</h3>
                  <div class="row row-cards">
                    <div
                      v-for="manager, index in managers"
                      :key="index"
                      class="col-12"
                    >
                      <div class="row align-items-center justify-content-between">
                        <div class="col">
                          <MoleculeAvatarDetails
                            :title="manager.name"
                            icon-size="md"
                            body-class="ms-2"
                            title-class="fs-h3"
                            class="col-12"
                          >
                            <template #subtitle>
                              <span class="d-block text-reset mt-2">
                                {{ manager.phone }}
                              </span>
                            </template>
                          </MoleculeAvatarDetails>
                        </div>
                        <div class="col-auto">
                          <AtomButton
                            v-if="manager.phone"
                            :href="'tel:' + manager.phone"
                            :title="$t('action.call')"
                            variant="ghost"
                            color="teal"
                          >
                            <phone-icon />
                          </AtomButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </MoleculeCard>
              </div>
              <div class="col-12">
                <MoleculeCard
                  href="https://drive.google.com/drive/folders/1vWkXDOLdOtMV9wWfm6eSjkEMzwVvgrp9?usp=sharing"
                  target="_blank"
                  class="card-form"
                  :title="$t('file.manager.to-google-drive')"
                >
                  <template #actions>
                    <external-link-icon class="me-2" />
                  </template>
                </MoleculeCard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import MoleculePageHeader from '@/components/MoleculePageHeader'
import MoleculeCard from '@/components/MoleculeCard'
import MoleculeEmpty from '@/components/MoleculeEmpty'
import MoleculeAvatarDetails from '@/components/MoleculeAvatarDetails'
import AtomButton from '@/components/AtomButton'

export default {
  title: vm => vm.$t('pages.projects.title'),

  components: {
    MoleculePageHeader,
    MoleculeCard,
    MoleculeEmpty,
    MoleculeAvatarDetails,
    AtomButton,
  },

  data: () => ({
    loading: true,
  }),

  computed: {
    ...mapGetters(['allProjects']),

    managers: () => [
      {
        name: 'John Doe',
        phone: '+44 (020) 0000 0002',
      },
      {
        name: 'Jane Doe',
        phone: '+44 (020) 0000 0003',
      }
    ],
  },

  async mounted () {
    try {
      await this.fetchProjects()
    } catch {} finally {
      this.loading = false
    }
  },

  methods: mapActions(['fetchProjects']),
}
</script>
