<template>
  <div class="page-header d-print-none">
    <div class="container-xl">
      <div class="row align-items-center">
        <div
          class="col"
          :class="{ 'col-auto': align === 'left' }"
        >
          <div
            v-if="pretitle"
            class="page-pretitle"
            v-text="pretitle"
          />
          <h2
            v-if="!!$slots.title || title"
            class="page-title"
          >
            <slot name="title">{{ title }}</slot>
          </h2>
          <div
            v-if="!!$slots.subtitle || subtitle"
            class="text-muted mt-1"
          >
            <slot name="subtitle">{{ subtitle }}</slot>
          </div>
        </div>
        <div
          v-if="!!$slots.default"
          class="col-auto"
          :class="{ 'ms-auto': align !== 'left' }"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MoleculePageHeader',

  props: {
    pretitle: String,
    title: String,
    subtitle: String,

    align: {
      type: String,
      validator: () => ['between', 'left'],
      default: 'between',
    },
  },
}
</script>
